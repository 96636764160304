import InfoIcon from 'shared/icons/info-icon'

export const SectionMessage = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => (
  <div
    className={`[&_a]:underline relative flex flex-wrap gap-1 items-center rounded-lg border p-2 border-danger bg-danger/10 ${
      className || ''
    }`}
  >
    <div className="flex gap-2 items-center">
      <InfoIcon className="shrink-0" />
      <span className="flex gap-1 flex-wrap text-sm font-normal text-danger">{children}</span>
    </div>
  </div>
)
