import { useEffect, useState } from 'react'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import {
  DashboardMailEditorProps,
  ExtendedState,
} from 'shared/components/dashboard-mail-editor/types/common'
import { isWithRecipient } from 'shared/components/dashboard-mail-editor/utils/is-with-recipients'

export const useSetDefaultEmailOptions = <T extends ExtendedState = ExtendedState>(
  data: DashboardMailEditorProps<T>['tempState'],
  onChange: DashboardMailEditorProps<T>['onChangeEmail'],
  options?: Pick<DashboardMailEditorProps<T>, 'withRecipient'>,
) => {
  const [isOptionsApplied, setIsOptionsApplied] = useState(false)
  const { data: emailOptions } = useEmailOptions()

  useEffect(() => {
    if (isOptionsApplied || !emailOptions || !data) return
    if (options?.withRecipient && isWithRecipient(options?.withRecipient, data)) {
      onChange({
        ...data,
        fromEmail: data.fromEmail || emailOptions.senderEmailAddress,
        fromName: data.fromName || emailOptions.senderName,
        recipient: data.recipient || emailOptions.testEmailAddress,
      })
    } else {
      onChange({
        ...data,
        fromEmail: data.fromEmail || emailOptions.senderEmailAddress,
        fromName: data.fromName || emailOptions.senderName,
      })
    }
    setIsOptionsApplied(true)
  }, [isOptionsApplied, onChange, options, data, options?.withRecipient, emailOptions])

  return { isOptionsApplied }
}
