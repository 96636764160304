import { useEffect } from 'react'
import { useSenderEmailValidList } from 'shared/components/sender-email-select/hooks/use-sender-email-valid-list'
import { SenderEmailSelectProps } from 'shared/components/sender-email-select/index'

export const useSetDefaultSenderEmail = (
  value: SenderEmailSelectProps['value'],
  onChange: SenderEmailSelectProps['onChange'],
) => {
  const { data: senderEmailValidList } = useSenderEmailValidList()

  useEffect(() => {
    if (!value) return
    if (!senderEmailValidList) return
    if (!senderEmailValidList.map(({ email }) => email).includes(value.toString())) {
      onChange('')
    }
  }, [senderEmailValidList, value])

  return { senderEmailValidList }
}
