import React from 'react'

export interface ButtonLikeLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const ButtonLikeLink = (props: ButtonLikeLinkProps) => {
  const { children, ...rest } = props
  const baseClassName = `flex items-center
    text-blue 
    [&>svg>path]:fill-blue main-transition-colors [&>svg>path]:main-transition-colors`

  return (
    <button
      {...rest}
      className={`
        ${baseClassName}
        ${
          rest.disabled
            ? 'text-gray [&>svg>path]:fill-gray'
            : '[&:hover]:text-gray [&:hover>svg>path]:fill-gray'
        }
        ${props.className || ''} 
      `}
    >
      {children}
    </button>
  )
}

export default ButtonLikeLink
