import React from 'react'

function DownloadIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8519 10.2206V0.866211H8.9426V10.3189L5.94167 7.31566L3.88501 9.37464L10.3481 15.8378L16.8939 9.29391L14.8351 7.23741L11.8519 10.2206Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4646 15.7612V17.9567H3.29433V15.7612H0.38501V20.866H20.3742V15.7612H17.4646Z"
      />
    </svg>
  )
}

export default DownloadIcon
