import juice from 'juice'
import { ckeditorContentStyles } from 'shared/components/dashboard-mail-editor/constants/ckeditor-content-styles'

export const getStyledBody = (body?: string): string => {
  if (!body) return ''

  const bodyWithoutEmptyImg = body.replaceAll('<img>', '')

  const bodyContent = getBodyContent(bodyWithoutEmptyImg)

  return juice.inlineContent(
    `<head><title></title></head><body><div id='ckeditor-wrapper' style='font-size: 15px !important;'>${bodyContent}</div></body>`,
    ckeditorContentStyles,
  )
}

export const getBodyContent = (html: string) => {
  const tempHTML = document.createElement('html')
  tempHTML.innerHTML = html

  // To prevent images stretching
  const imgTags = tempHTML.querySelectorAll('img')
  imgTags.forEach(img => {
    img.removeAttribute('height')
  })

  return tempHTML.querySelector('#ckeditor-wrapper')?.innerHTML || html
}
