import { isAttachmentFile } from 'modules/profile/mailing-settings/utils/file-utils'
import { AttachmentFileInterface } from '../types/email-interface'
import { trimName } from './trim-name'

export const compareAttachmentNames =
  (file: File) => (attachment: AttachmentFileInterface | File) => {
    const rawFileName = trimName(file.name.slice(0, file.name.lastIndexOf('.')))
    const compareTo = isAttachmentFile(attachment) ? attachment.file.name : attachment.name
    return compareTo.includes(rawFileName)
  }
