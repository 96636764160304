import { TagInterface } from 'modules/newsletters/types/newsletter-interface'
import React from 'react'
import ButtonLikeLink from 'shared/components/button-like-link'
import FormSelectMultiple from 'shared/components/form-select-multiple'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import { ContactsSettings, ContactsSettingsBlockProps } from '.'

export interface TagsBlockProps
  extends Pick<ContactsSettingsBlockProps, 'isPreFetching' | 'disabled'> {
  title: string
  onChangeMultiselect: (
    value: ContactsSettings['tags']['excluded'] | ContactsSettings['tags']['included'],
  ) => void
  onClickTagLabel: (tagId: TagInterface['id']) => void
  onSelectAll: () => void
  onUnselectAll: () => void
  type?: 'included' | 'excluded'
  tagsList: ContactsSettings['tags']['excluded'] | ContactsSettings['tags']['included']
  listOfTagsOptions: { id: number; caption: string }[] | undefined
  required?: boolean
}

const TagsBlock = ({
  disabled,
  isPreFetching,
  title,
  required,
  onChangeMultiselect,
  onClickTagLabel,
  onSelectAll,
  onUnselectAll,
  type = 'included',
  tagsList,
  listOfTagsOptions,
}: TagsBlockProps) => {
  const { t } = useLocoTranslation()

  return (
    <div className={`overflow-y-hidden rounded-md bg-white w-full flex flex-col`}>
      <div className={`flex flex-col gap-1 mt-5`}>
        <FormSelectMultiple
          required={required}
          hideSelectedValues
          disabled={disabled}
          isPreFetching={isPreFetching}
          label={title}
          data={listOfTagsOptions}
          value={tagsList}
          onChange={onChangeMultiselect}
        />
        <div className="flex gap-1 justify-end">
          <ButtonLikeLink
            disabled={disabled || isPreFetching}
            className={`${isPreFetching ? 'text-transparent' : ''}}`}
            onClick={onSelectAll}
          >
            {t('dashboard.contact_email_settings.tags_selector.select_all')}
          </ButtonLikeLink>
          |
          <ButtonLikeLink
            disabled={disabled || isPreFetching}
            className={`${isPreFetching ? 'text-transparent' : ''}}`}
            onClick={onUnselectAll}
          >
            {t('dashboard.contact_email_settings.tags_selector.unselect_all')}
          </ButtonLikeLink>
        </div>
      </div>
      {!isPreFetching && (
        <ul className={`flex gap-1 flex-wrap pt-1 mb-5 max-h-44 overflow-auto`}>
          {tagsList.map(currentId => (
            <li
              key={currentId}
              className={`
              text-sm
              ${type === 'included' ? 'bg-lightblue text-darkblue' : 'bg-[#FEF3F2] text-[#F04438]'}
              h-fit py-1 px-2 w-fit rounded-full
              flex gap-1.5 items-center
              cursor-default
              main-transition-colors 
            `}
            >
              {listOfTagsOptions &&
                (listOfTagsOptions?.find(option => option.id === currentId) || {}).caption}
              <CloseIcon
                className={`
                ${!disabled ? 'cursor-pointer' : ''}
                ${type === 'included' ? 'fill-darkblue' : 'fill-[#F04438]'}`}
                onClick={() => !disabled && onClickTagLabel(currentId)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default TagsBlock
