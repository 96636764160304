import {
  getFilteredAttachmentsList,
  isAttachmentFile,
} from 'modules/profile/mailing-settings/utils/file-utils'
import React from 'react'
import { AttachmentFileInterface } from 'shared/components/dashboard-mail-editor/types/email-interface'
import { FileSelector } from 'shared/components/file-selector'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BinIcon from 'shared/icons/bin-icon'
import DonwloadIcon from 'shared/icons/donwload-icon'
import { DashboardMailEditorCommonProps } from '../types/common'
import { concatAttachments } from '../utils/concat-attachments'

export interface AttachmentsBlockProps
  extends Pick<DashboardMailEditorCommonProps, 'isPreFetching' | 'inModal' | 'disabled'> {
  attachmentsTempState: (AttachmentFileInterface | File)[]
  setAttachmentsTempState: React.Dispatch<React.SetStateAction<(AttachmentFileInterface | File)[]>>
}

const AttachmentsBlock = ({
  disabled,
  isPreFetching,
  attachmentsTempState,
  setAttachmentsTempState,
  inModal,
}: AttachmentsBlockProps) => {
  const { t } = useLocoTranslation()

  return (
    <div className={'overflow-y-hidden rounded-md bg-white w-full flex flex-col'}>
      <span
        className={`${
          inModal ? 'pb-5' : 'p-5'
        } border-b border-gray/30 font-bold text-darkblue text-[17px]`}
      >
        {t('dashboard.email.attachments')}
      </span>
      <div className={`${inModal ? 'pt-5' : 'p-5'} gap-5 flex flex-col`}>
        {attachmentsTempState.length > 0 && (
          <div className="flex flex-col gap-4">
            {attachmentsTempState.map(attachment => {
              const key = isAttachmentFile(attachment)
                ? attachment.id
                : `${attachment.name}-${attachment.size}`

              return (
                <div key={key} className="flex flex-row gap-2 break-all">
                  {isAttachmentFile(attachment) ? attachment.file?.name : attachment.name}
                  {isAttachmentFile(attachment) && (
                    <Tooltip
                      className="shrink-0"
                      wrapperClassName={'h-fit w-fit self-center'}
                      mode={'hover'}
                      label={t('global.download')}
                      disabled={isPreFetching}
                    >
                      <a
                        target={'_blank'}
                        rel={'noreferrer'}
                        href={attachment.file.path}
                        className={'group outline-none'}
                      >
                        <DonwloadIcon
                          className={`main-transition-colors h-[16px] w-[16px]
                          ${
                            isPreFetching
                              ? 'cursor-default'
                              : 'group-hover:fill-blue group-focus-visible:fill-blue'
                          }`}
                        />
                      </a>
                    </Tooltip>
                  )}
                  <Tooltip
                    className="shrink-0"
                    wrapperClassName={'h-fit w-fit self-center'}
                    mode={'hover'}
                    label={t('global.delete')}
                    disabled={isPreFetching}
                  >
                    <button
                      disabled={disabled}
                      onClick={() =>
                        !disabled &&
                        !isPreFetching &&
                        setAttachmentsTempState(
                          getFilteredAttachmentsList(attachmentsTempState, attachment),
                        )
                      }
                      className={'group outline-none'}
                    >
                      <BinIcon
                        className={`
                        main-transition-colors h-[16px] w-[16px]
                        ${
                          disabled || isPreFetching
                            ? 'cursor-default'
                            : 'group-hover:fill-danger group-focus-visible:fill-danger '
                        }`}
                      />
                    </button>
                  </Tooltip>
                </div>
              )
            })}
          </div>
        )}
        <FileSelector
          disabled={disabled}
          isPreFetching={isPreFetching}
          label={t('dashboard.email.add_attachments')}
          onSelectFile={file => setAttachmentsTempState(prev => concatAttachments(prev, file))}
        />
      </div>
    </div>
  )
}

export default AttachmentsBlock
