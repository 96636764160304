import {
  EmailBaseInterface,
  EmailRecipientInterface,
} from 'shared/components/dashboard-mail-editor/types/email-interface'

export const isWithRecipient = (
  withRecipient: boolean,
  tempState: EmailBaseInterface | EmailRecipientInterface | undefined | null,
): tempState is EmailRecipientInterface | undefined | null => {
  return withRecipient
}
