import { getEmailNewEditorUrl } from 'modules/email/utils/get-email-new-editor-url'
import React, { useLayoutEffect, useRef } from 'react'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import { twJoin } from 'tailwind-merge'
import { ExtendedState } from '../../types/common'

export type NewEditorProps = {
  tempState: ExtendedState
  isPreFetching?: boolean
  mailingId: MailingId
}

export const NewEditor = ({ tempState, isPreFetching, mailingId }: NewEditorProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const iframeDocument = iframeRef.current?.contentDocument

  useLayoutEffect(() => {
    // NOTE: add link for opening email editor inside iframe, it unreal to put the link outside the iframe and make the iframe scrollable
    const addLink = () => {
      if (iframeDocument?.body) {
        const link = iframeDocument.createElement('a')
        link.style.position = 'fixed'
        link.style.width = '100%'
        link.style.height = '100%'
        link.style.top = '0'
        link.style.left = '0'
        link.href = getEmailNewEditorUrl(mailingId as MailingId)
        link.target = '_parent'
        if (isPreFetching) {
          link.style.display = 'none'
        }
        iframeDocument.body.appendChild(link)
      }
    }
    addLink()
    iframeDocument?.addEventListener('DOMContentLoaded', addLink)
    return () => {
      iframeDocument?.removeEventListener('DOMContentLoaded', addLink)
    }
  }, [
    iframeRef,
    tempState?.id,
    iframeDocument,
    iframeDocument?.body,
    tempState.useNewEditor,
    isPreFetching,
  ])

  return (
    <iframe
      ref={iframeRef}
      className={twJoin(`w-full h-full overflow-hidden`, isPreFetching && 'pointer-events-none')}
      srcDoc={tempState.html ?? ''}
      seamless
    />
  )
}
