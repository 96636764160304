export const ckeditorContentStyles = `<styles>
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
 .text-tiny {
    font-size: .7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
 .text-small {
    font-size: .85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
 .text-big {
    font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
 .text-huge {
    font-size: 1.8em;
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
 code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
 blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
/* To prevent images stretching */
img {
  height: initial;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
 .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
 .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
 .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
 .image-inline picture {
    display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
 .image-inline picture,
 .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
 .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
    margin: 0 auto;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
 .image.image_resized > figcaption {
    display: block;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
 .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: #333;
    background-color: #f7f7f7;;
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-block-align-left,
 .image-style-block-align-right {
    max-width: calc(100% - 1.5em);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-align-left,
 .image-style-align-right {
    clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-side {
    float: right;
    margin-left: 1.5em;
    max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-align-left {
    float: left;
    margin-right: 1.5em;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-align-right {
    float: right;
    margin-left: 1.5em;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 p + .image-style-align-left,
 p + .image-style-align-right,
 p + .image-style-side {
    margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-inline.image-style-align-left,
 .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
 .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
 .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ol {
    list-style-type: decimal;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ol ol {
    list-style-type: lower-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ol ol ol {
    list-style-type: lower-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ol ol ol ol {
    list-style-type: upper-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ol ol ol ol ol {
    list-style-type: upper-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ul {
    list-style-type: disc;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ul ul {
    list-style-type: circle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ul ul ul {
    list-style-type: square;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
 ul ul ul ul {
    list-style-type: square;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
 .table {
    margin: 0.9em auto;
    display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
 .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
 .table table td,
 .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
 .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
[dir="rtl"] .table th {
    text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
[dir="ltr"] .table th {
    text-align: left;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
 .table .ck-table-resized {
    table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
 .table table {
    overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
 .table td,
 .table th {
    position: relative;
}
</styles>`
