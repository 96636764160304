import { COPY_POSTFIX } from '../constants/copy-postfix'
import { trimName } from './trim-name'

export const modifyCopyName = (file: File, copyNumber: number) => {
  const name = file.name
  const extensionIndex = name.lastIndexOf('.')
  const newBaseName = trimName(
    name.slice(0, extensionIndex) + (copyNumber > 0 ? `${COPY_POSTFIX} ${copyNumber}` : ''),
  )
  const newName = newBaseName + name.slice(extensionIndex)

  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  })
}
