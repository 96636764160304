import React from 'react'
import FormInput, { InputProps, handleNumberInput } from '.'

export interface FormInputIntegerNumbersProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: number) => void
}

function FormInputIntegerPositiveNumbers({ onChange, ...props }: FormInputIntegerNumbersProps) {
  return (
    <FormInput min="0" onChange={e => onChange(handleNumberInput(e.target.value))} {...props} />
  )
}

export default FormInputIntegerPositiveNumbers
