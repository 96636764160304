import React from 'react'

function BinIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.56799 23.3918H15.222C16.1299 23.3918 16.8683 22.6533 16.8683 21.7455V8.73633H2.92188V21.7455C2.92188 22.6534 3.66036 23.3918 4.5682 23.3918H4.56799ZM12.5703 12.1425C12.5703 11.8395 12.816 11.5938 13.1191 11.5938C13.4222 11.5938 13.6679 11.8395 13.6679 12.1425V18.897C13.6679 19.2001 13.4222 19.4458 13.1191 19.4458C12.816 19.4458 12.5703 19.2001 12.5703 18.897V12.1425ZM6.42969 12.1425C6.42969 11.8395 6.67548 11.5938 6.97849 11.5938C7.2815 11.5938 7.52729 11.8395 7.52729 12.1425V18.897C7.52729 19.2001 7.28153 19.4458 6.97849 19.4458C6.67548 19.4458 6.42969 19.2001 6.42969 18.897V12.1425Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0161 5.22538C19.0161 4.62019 18.5238 4.12782 17.9186 4.12782H13.6663V2.97832C13.6663 1.76787 12.6816 0.783203 11.4712 0.783203H8.31644C7.10598 0.783203 6.12134 1.76787 6.12134 2.97832V4.12782H1.86904C1.26385 4.12782 0.771484 4.62015 0.771484 5.22538V7.64003H19.0164L19.0161 5.22538ZM12.5683 4.12792H7.21875V2.97842C7.21875 2.37322 7.71107 1.88086 8.3163 1.88086H11.471C12.0762 1.88086 12.5686 2.3732 12.5686 2.97842L12.5683 4.12792Z"
      />
    </svg>
  )
}

export default BinIcon
