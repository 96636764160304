export enum MailingPoolStatusEnum {
  quarantine = 'quarantine',
  high_risk = 'high-risk',
  moderate_risk = 'moderate-risk',
  low_risk = 'low-risk',
  new_user = 'new-user',
}

export const MailingPoolStatusHelpMessages = {
  [MailingPoolStatusEnum.quarantine]: 'dashboard.email.help_message.quarantine_pool_advise',
  [MailingPoolStatusEnum.high_risk]: 'dashboard.email.help_message.high_risk_pool_advise',
  [MailingPoolStatusEnum.moderate_risk]: 'dashboard.email.help_message.moderate_risk_pool_advise',
  [MailingPoolStatusEnum.low_risk]: 'dashboard.email.help_message.low_risk_pool_advise',
  [MailingPoolStatusEnum.new_user]: 'dashboard.email.help_message.new_user_pool_advise',
}

export const MailingPoolStatusColors = {
  [MailingPoolStatusEnum.quarantine]: 'danger',
  [MailingPoolStatusEnum.high_risk]: 'danger',
  [MailingPoolStatusEnum.moderate_risk]: 'yellow',
  [MailingPoolStatusEnum.low_risk]: 'green',
  [MailingPoolStatusEnum.new_user]: 'blue',
}

export const MailingPoolStatusTitles = {
  [MailingPoolStatusEnum.quarantine]: 'dashboard.email.pool.quarantine',
  [MailingPoolStatusEnum.high_risk]: 'dashboard.email.pool.high_risk',
  [MailingPoolStatusEnum.moderate_risk]: 'dashboard.email.pool.moderate_risk',
  [MailingPoolStatusEnum.low_risk]: 'dashboard.email.pool.low_risk',
  [MailingPoolStatusEnum.new_user]: 'dashboard.email.pool.new_user',
}
