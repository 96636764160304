import { EMAIL_API } from 'modules/email/api/email-api'
import { EmailTemplateItemInterface } from 'modules/email/types/email-template-interface'
import { useMemo } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { DataWithPaginationInterface } from 'shared/types/pagination-interface'
import useSWR from 'swr'

export function useGetEmailTemplates() {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    DataWithPaginationInterface<EmailTemplateItemInterface>
  >({ method: RequestMethodsEnum.get })

  const { data, mutate: mutateTemplates } = useSWR(() => user && `${EMAIL_API}/templates`, fetcher)

  const templates = useMemo(() => data?.items, [data])

  return { templates, mutateTemplates }
}
