import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'
import { SENDER_EMAIL_VALID_LIST } from '../api'

export const useSenderEmailValidList = () => {
  const { user } = useUser()
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    { email: string }[]
  >({
    method: RequestMethodsEnum.get,
  })

  const { data } = useSWR(user && SENDER_EMAIL_VALID_LIST, fetcher)

  return {
    data,
    isFetching,
  }
}
