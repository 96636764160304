import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from 'shared/components/form/field-label'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import SearchIcon from 'shared/icons/search-icon'
import { getDataTestAttributes } from 'shared/utils/get-data-test-attributes'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    onClear: () => void
    placeholder?: string
    testAttributePostfix?: string
    isPreFetching?: boolean
    inputClassName?: string
  }

function FormInputSearch({
  label,
  labelClassName,
  required,
  value,
  onChange,
  onFocus,
  maxLength,
  error,
  placeholder,
  onClear,
  description,
  testAttributePostfix,
  isPreFetching,
  ...rest
}: InputProps) {
  const { t } = useLocoTranslation()
  return (
    <label className={`${rest.className} relative text-sm font-medium flex flex-col gap-1`}>
      <FieldLabel label={label} labelClassName={labelClassName} required={required} />
      {value === '' ? (
        <SearchIcon
          className={`absolute right-4 w-[21px] h-[21px] translate-y-1/2 scale-75 z-1 ${
            label && 'top-[24px]'
          }`}
        />
      ) : (
        <CloseIcon
          onClick={onClear}
          className={`absolute right-2 w-[21px] h-[21px] translate-y-1/2 z-1 fill-darkblue  cursor-pointer scale-75 ${
            label && 'top-[24px]'
          } `}
        />
      )}
      <input
        className={`relative w-full text-darkblue main-transition-colors rounded-lg border ${
          error ? 'border-danger' : 'border-gray/30'
        } py-2.5 pl-4 pr-8 focus:border-blue focus:outline-none placeholder:text-gray-300/70 ${
          isPreFetching
            ? 'animate-pulse bg-gray-600/40 text-transparent disabled:bg-gray-600/40'
            : 'disabled:bg-gray-200/40'
        } ${rest.inputClassName || ''}`}
        onKeyDown={evt =>
          rest.type === 'number' && ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
        }
        type={rest.type || 'text'}
        disabled={rest.disabled || isPreFetching}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        placeholder={placeholder || t('global.search')}
        spellCheck={false}
        {...getDataTestAttributes(!!testAttributePostfix, `${testAttributePostfix}`)}
      />
      <FieldErrorAndDescription description={description} error={error} />
    </label>
  )
}

export default FormInputSearch
