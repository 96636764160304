// TODO: reuse in "email-template-selector"
import { EmailTemplateItemInterface } from 'modules/email/types/email-template-interface'
import { SuccessButton } from 'shared/components/button'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CheckCircleIcon from 'shared/icons/check-circle-icon'

export interface EmailTemplateSelectorNewEditorProps {
  value?: EmailTemplateItemInterface | null
  onChange: (value: EmailTemplateItemInterface) => void
  templates?: EmailTemplateItemInterface[]
}

const EmailTemplateSelectorNewEditor = ({
  onChange,
  value,
  templates,
}: EmailTemplateSelectorNewEditorProps) => {
  const { t } = useLocoTranslation()
  return (
    <div className={`overflow-auto`}>
      <ul className={`flex gap-5 flex-wrap flex-col sm:flex-row items-center justify-center pb-5`}>
        {templates ? (
          templates.map((template, idx) => {
            return (
              <li key={idx}>
                <div
                  className={`relative overflow-hidden justify-start group flex flex-col items-center w-[230px] h-[250px] sm:w-[230px] sm:h-[260px] border-2 border-transparent relative shadow-lg rounded-lg outline-none focus-visible:border-blue ${
                    template.id === value?.id && 'border-green'
                  } main-transition-colors`}
                >
                  <div
                    className={`w-full h-full z-1 rounded-lg bg-white/70 ${
                      value === value?.id ? '' : 'hidden group-hover:flex'
                    } flex flex-col gap-5 justify-center items-center justify-self-stretch`}
                  >
                    {template.id === value?.id ? (
                      <div className={`flex gap-1 max-w-[180px] items-center h-[40px]`}>
                        <CheckCircleIcon className={'fill-green'} />
                        <span className={'text-green break-all font-bold'}>
                          {t('dashboard.email_template_selector.template_selected')}
                        </span>
                      </div>
                    ) : (
                      <SuccessButton
                        onClick={e => {
                          e.preventDefault()
                          onChange(template)
                        }}
                        className={'max-w-[180px]'}
                      >
                        <CheckCircleIcon className={'fill-white w-[20px] h-[20px]'} />
                        <span className={'text-white break-words'}>
                          {t('dashboard.email_template_selector.select_template')}
                        </span>
                      </SuccessButton>
                    )}
                  </div>
                  <img
                    src={template.previewUrl || ''}
                    className={`absolute rounded-lg h-full object-cover`}
                  />
                </div>
              </li>
            )
          })
        ) : (
          <div className="flex justify-items-center">
            <Loader />
          </div>
        )}
      </ul>
    </div>
  )
}

export default EmailTemplateSelectorNewEditor
