import { Float } from '@headlessui-float/react'
import { Combobox } from '@headlessui/react'
import { Virtualizer, useVirtualizer } from '@tanstack/react-virtual'
import React, { useEffect, useRef, useState } from 'react'
import FormInputSearch from 'shared/components/form-input/form-input-search'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import ArrowDown from 'shared/icons/arrow-down-icon'
import CheckIcon from 'shared/icons/check-icon'
import CloseIcon from 'shared/icons/close-icon'
import { twJoin, twMerge } from 'tailwind-merge'
import CommonListOption from './form/common-list-option'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from './form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from './form/field-label'
import { Loader } from './loader'

export interface DataInterface<T extends { id: string | number; caption: string }> {
  id: T['id']
  caption: string
}

type FormSelectProps<T extends { id: string | number; caption: string }> = FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    data: T[] | undefined
    onChange: (data: DataInterface<T>['id'][]) => void
    isPreFetching?: boolean
    value: DataInterface<T>['id'][]
    placeholder?: string
    className?: string
    wrapperClassName?: string
    disabled?: boolean
    withoutCloseIcon?: boolean
    hideSelectedValues?: boolean
    dynamicSearch?: {
      onChangeSearch?: (value: string) => void
      hasMore?: boolean
      isFetching?: boolean
      count?: number
    }
  }

function FormSelectMultiple<T extends { id: string | number; caption: string }>({
  label,
  labelClassName,
  data,
  onChange,
  value,
  isPreFetching,
  placeholder = 'global.select',
  error,
  required,
  description,
  className,
  wrapperClassName,
  disabled,
  withoutCloseIcon,
  hideSelectedValues,
  dynamicSearch,
}: FormSelectProps<T>) {
  const { t } = useLocoTranslation()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const parentRef = useRef<HTMLDivElement>(null)

  const [search, setSearch] = useState('')

  const [selectedData, setSelectedData] = useState<T[] | undefined>(
    data ? data.filter(el => value?.includes(el.id)) : undefined,
  )

  useEffect(() => {
    setSelectedData(data ? data.filter(el => value?.includes(el.id)) : undefined)
  }, [data, value])

  const [query, setQuery] = useState('')

  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    setFilteredData(() =>
      query === ''
        ? data
        : data?.filter((item: T) =>
            item.caption
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          ) ?? [],
    )
  }, [data, query])

  const rowVirtualizer = useVirtualizer({
    count: filteredData?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
  })

  const disabledNoDataWithValue = value.length !== 0 && !data

  return (
    <div className={`relative flex flex-col gap-1 ${wrapperClassName || ''}`}>
      <Combobox
        multiple
        value={selectedData}
        onChange={data => data && onChange(data.map(el => el.id))}
        disabled={disabled || isPreFetching || disabledNoDataWithValue}
      >
        {({ open }) => (
          <Float
            offset={4}
            portal
            onUpdate={() => {
              if (dropdownRef.current && inputRef.current) {
                const buttonWidth = inputRef.current.getBoundingClientRect().width
                const dropdownWidth = dropdownRef.current.getBoundingClientRect().width
                if (dropdownWidth < buttonWidth) {
                  dropdownRef.current.style.width = `${buttonWidth}px`
                }
                if (searchRef.current) {
                  searchRef.current.style.width = `${buttonWidth}px`
                }
              }
            }}
            placement={'bottom-end'}
            flip
            as={'div'}
            onHide={() => setQuery('')}
          >
            <div className="flex flex-col gap-1">
              <FieldLabel
                wrapperAs={Combobox.Label}
                label={label}
                required={required}
                labelClassName={`text-sm font-medium ${labelClassName}`}
              />
              <div className="relative w-full cursor-default overflow-hidden rounded-lg text-left">
                <Combobox.Input
                  className={twMerge(
                    'sm:min-w-[200px] relative flex justify-between items-center w-full py-2.5 pr-[59px] pl-4 rounded-lg',
                    disabled || disabledNoDataWithValue
                      ? isPreFetching
                        ? 'bg-gray-600/40'
                        : 'bg-gray-200/40'
                      : 'bg-white',
                    'text-darkblue main-transition-colors cursor-default text-left text-sm border',
                    open ? 'border-blue' : error ? 'border-danger' : 'border-gray/30',
                    open && 'border-blue',
                    'focus:outline-none focus-visible:border-blue',
                    className,
                    isPreFetching && 'animate-pulse bg-gray-600/40',
                    isPreFetching ? 'text-transparent' : 'text-darkblue',
                    isPreFetching ? 'placeholder:text-transparent' : 'placeholder:text-gray-300/70',
                    dynamicSearch && 'pointer-events-none',
                  )}
                  ref={inputRef}
                  onChange={event => {
                    const [lastElement] = event.target.value.split(',').slice(-1)
                    setQuery(lastElement)
                  }}
                  displayValue={(items: T[]) => {
                    if (hideSelectedValues || !items.length) {
                      return ''
                    }

                    return items
                      .map(el => el.caption)
                      .join(', ')
                      .concat(', ')
                  }}
                  placeholder={t(placeholder)}
                />
                {value.length !== 0 && !withoutCloseIcon && (
                  <div
                    onClick={e => {
                      if (disabled || disabledNoDataWithValue || isPreFetching) return
                      e.stopPropagation()
                      onChange([])
                    }}
                    className={`absolute inset-y-0 right-0 flex items-center pr-10`}
                  >
                    <CloseIcon
                      className={`${
                        disabled || disabledNoDataWithValue || isPreFetching
                          ? 'cursor-default fill-transparent'
                          : 'cursor-pointer fill-darkblue'
                      }`}
                    />
                  </div>
                )}
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-4">
                  <ArrowDown
                    className={twMerge(
                      disabled || disabledNoDataWithValue || isPreFetching
                        ? 'cursor-default stroke-transparent'
                        : 'cursor-pointer stroke-darkblue',
                      'transition-transform duration-300',
                      open && 'rotate-180',
                    )}
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
            </div>
            <Combobox.Options className={'relative focus-visible:outline-none'}>
              <div
                ref={dropdownRef}
                className={'bg-white rounded-lg border border-gray/30 shadow-around-sm'}
              >
                {dynamicSearch && (
                  <FormInputSearch
                    value={search}
                    onClear={() => setSearch('')}
                    onChange={e => {
                      setSearch(e.target.value)
                      dynamicSearch?.onChangeSearch && dynamicSearch.onChangeSearch(e.target.value)
                    }}
                    inputClassName={'rounded-b-none'}
                    placeholder={t('global.dynamic_search.start_typing')}
                    className={'m-[-1px] mb-0'}
                  />
                )}
                {data === undefined || dynamicSearch?.isFetching ? (
                  <div className="flex bg-white justify-center px-4 py-1 rounded-lg">
                    <Loader className="scale-75" />
                  </div>
                ) : (filteredData || []).length > 20 ? (
                  <VirtualizedList
                    data={filteredData}
                    value={value}
                    parentRef={parentRef}
                    rowVirtualizer={rowVirtualizer}
                    firstRect={!!dynamicSearch}
                    lastRect={dynamicSearch?.hasMore}
                  />
                ) : (
                  <NormalList
                    data={filteredData}
                    value={value}
                    firstRect={!!dynamicSearch}
                    lastRect={dynamicSearch?.hasMore}
                  />
                )}
                {dynamicSearch?.hasMore && (
                  <div className={'px-4 py-1 text-gray-300/70 text-xs border-t border-gray/30'}>
                    {t('global.dynamic_search.has_more_results', {
                      count: dynamicSearch?.count || 100,
                    })}
                  </div>
                )}
              </div>
            </Combobox.Options>
          </Float>
        )}
      </Combobox>
      <FieldErrorAndDescription description={description} error={error} />
    </div>
  )
}

function NormalList<T extends { id: string | number; caption: string }>({
  data,
  value,
  firstRect,
  lastRect,
}: {
  data?: T[]
  value?: DataInterface<T>['id'][]
  firstRect?: boolean
  lastRect?: boolean
}) {
  return (
    <div
      className={twMerge(
        'border-collapse max-h-60 w-full overflow-auto',
        !firstRect && 'rounded-t-lg',
        !lastRect && 'rounded-b-lg',
        'bg-white text-sm focus:outline-none customScroll',
      )}
    >
      {data?.map(item => (
        <CommonListOption
          item={item}
          key={item.id}
          value={value}
          firstRect={firstRect}
          lastRect={lastRect}
          as={Combobox.Option}
        />
      ))}
    </div>
  )
}

function VirtualizedList<T extends { id: string | number; caption: string }>({
  data,
  value,
  parentRef,
  rowVirtualizer,
  firstRect,
  lastRect,
}: {
  fullData?: T[]
  data?: T[]
  value?: DataInterface<T>['id'][]
  parentRef: React.RefObject<HTMLDivElement>
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>
  firstRect?: boolean
  lastRect?: boolean
}) {
  return (
    <div
      ref={parentRef}
      className={twMerge(
        'border-collapse max-h-60 w-full overflow-auto',
        firstRect ? 'rounded-b-lg' : 'rounded-lg',
        'bg-white text-sm focus:outline-none customScroll',
      )}
    >
      <div
        style={{
          height: rowVirtualizer.getTotalSize(),
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
          <CommonListOption
            item={data?.[virtualRow.index]}
            data-index={virtualRow.index}
            value={value}
            key={virtualRow.key}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${virtualRow.start}px)`,
            }}
            optionRef={rowVirtualizer.measureElement}
            firstRect={firstRect}
            lastRect={lastRect}
            as={Combobox.Option}
          />
        ))}
      </div>
    </div>
  )
}

export default FormSelectMultiple
