import { AttachmentFileInterface } from '../types/email-interface'
import { compareAttachmentNames } from './compare-attachment-names'
import { modifyCopyName } from './modify-copy-name'

export const concatAttachments = (prev: (AttachmentFileInterface | File)[], attachment: File) => {
  const sameNamedFiles = prev.filter(compareAttachmentNames(attachment))

  const updatedAttachment = modifyCopyName(attachment, sameNamedFiles.length)

  return [...prev, updatedAttachment]
}
