import { CONTACT_API } from 'modules/contacts/api/contact-api'
import { ContactBulkApiInterface } from 'modules/contacts/types/contact-menu-interface'
import { useEffect, useState } from 'react'
import useUser from 'shared/hooks/use-user'
import { DataWithPaginationInterface } from 'shared/types/pagination-interface'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

interface UseContactTagsProps {
  shouldFetch: boolean
}

export function useContactTags({ shouldFetch }: UseContactTagsProps) {
  const { user } = useUser()

  const [tags, setTags] = useState<
    DataWithPaginationInterface<ContactBulkApiInterface> | undefined
  >(undefined)

  const { data, mutate } = useSWR<DataWithPaginationInterface<ContactBulkApiInterface>>(() => {
    if (user && shouldFetch) {
      return `${CONTACT_API}/tags/list-all`
    }
    return null
  }, baseFetcher)
  useEffect(() => {
    if (user && data) {
      setTags(() => {
        if (data) {
          return {
            hasMore: data.hasMore,
            items: data.items.sort((a, b) => {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            }),
          }
        }
      })
    }
  }, [data])

  return { tags, mutate }
}
