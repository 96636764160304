import React from 'react'

function MagicWandIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.68787 22.6607L2.68807 22.6609L0.95896 20.9318C0.82491 20.7952 0.75 20.6117 0.75 20.4204C0.75 20.2293 0.82491 20.0457 0.95896 19.9091L10.1154 10.7286C10.252 10.5947 10.4356 10.5198 10.6268 10.5198C10.818 10.5198 11.0017 10.5948 11.1383 10.7286L12.8915 12.4818C13.0254 12.6184 13.1003 12.802 13.1003 12.9933C13.1003 13.1844 13.0254 13.368 12.8915 13.5046L3.71068 22.6607C3.57409 22.7947 3.39045 22.8696 3.19935 22.8696C3.00808 22.8696 2.82445 22.7947 2.68786 22.6607L2.68787 22.6607ZM14.8398 18.0293V13.456H16.301V18.0293H14.8398ZM13.4517 11.8975L11.7228 10.1686C11.5889 10.032 11.5139 9.84838 11.5139 9.65712C11.5139 9.46586 11.5889 9.28239 11.7228 9.14584V9.14568L13.9144 6.95413C14.051 6.82028 14.2346 6.74538 14.4259 6.74538C14.617 6.74538 14.8007 6.82029 14.9372 6.95413L16.6663 8.68317C16.8002 8.81976 16.8751 9.00334 16.8751 9.19445C16.8751 9.38575 16.8002 9.56937 16.6663 9.70592H16.6662L14.4745 11.8975C14.3379 12.0313 14.1545 12.1064 13.9632 12.1064C13.7719 12.1064 13.5883 12.0313 13.4517 11.8975ZM13.4517 11.8975L14.4517 12.8975L13.4517 11.8975ZM18.1761 11.5566V10.0954H22.7543V11.5566H18.1761ZM5.61024 8.78058V7.31939H10.1885V8.78058H5.61024ZM16.0892 6.50058L19.4298 3.16007L20.4629 4.19319L17.1223 7.5337L16.0892 6.50058ZM12.0636 5.43952V0.866211H13.5248V5.43952H12.0636Z"
        fill="#00A0FF"
      />
    </svg>
  )
}

export default MagicWandIcon
